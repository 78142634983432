/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#contactUs .background {
  min-height: 750px;
  background-image: url('../assets/images/backgrounds/pharmaRed.png');
  background-position: right bottom;
  background-repeat: no-repeat;
}

#contactUs h1 {
  font-size: 2.24em;
  padding: 30px 20px 10px 20px;
}

#contactUs .mainSection {
  width: 100%;
  background-color: rgb(241, 241, 241, 0.8);
}

#contactUs .mainSection .form-control {
  border-radius: 0px;
}

#contactUs .mainSection #thanks {
  height: 25vh;
}

#contactUs #sideBar {
  color: #fff;
  background-size: cover;
}

#contactUs #sideBar.colorBg {
  background-color: #4c4c4c;
}

#contactUs #sideBar a {
  color: #fff;
}

#contactUs #sideBar strong {
  font-size: 1.1em;
}

#contactUs #sideBar .phone,
#contactUs #sideBar .email {
  font-size: 1.2em;
}

#contactUs #sideBar .warning {
  font-size: 0.8em;
  line-height: 1.7em;
  padding: 25px;
  color: #fff;
  font-style: italic;
  background-color: #d63844;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
  #contactUs h1 {
    padding: 30px;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  #contactUs h1 {
    padding: 50px;
  }

  #contactUs .mainSection {
    width: 85%;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
}
