/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#vendors #government .padding,
#vendors #partners .padding {
  padding: 30px 5px 30px 5px;
}

#vendors #commercial {
  padding-top: 50px;
}

#vendors .sectionWidth {
  width: 100%;
}

#vendors .sectionTitle {
  font-size: 2.2em;
}

/* ------------------ */
/* --- Government --- */
/* ------------------ */

#vendors #government .logo {
  width: 130px;
  display: block;
  margin: auto;
  padding: 15px 0px 15px 0px;
}

#vendors #government .contracts {
  color: #4c4c4c;
  padding: 20px;
}

#vendors #government .bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5) 100%),
    url('../assets/images/banners/governmentBanner.jpg');
  background-size: cover;
}

#vendors #government .contracts ul {
  padding: 0;
  list-style: none;
}

#vendors #government .contracts a {
  color: inherit;
}

#vendors #government .contracts strong {
  color: #f06073;
}

/* ------------------ */
/* --- Commercial --- */
/* ------------------ */

#vendors #commercial .icon {
  width: 150px;
  padding: 15px;
}

#vendors #commercial.padding {
  padding-top: 50px;
}

/* ------------------ */
/* ---- Partners ---- */
/* ------------------ */

#vendors #partners .logo {
  width: 180px;
  display: block;
  margin: auto;
  padding: 40px 15px 40px 15px;
}

#vendors #partners .daleLogo {
  width: 110px;
  display: block;
  margin: auto;
  padding: 40px 15px 40px 15px;
}

#vendors #partners .waltzdLogo {
  width: 135px;
  display: block;
  margin: auto;
  padding: 40px 15px 40px 15px;
}

#vendors #partners .bg {
  background-image: url('../assets/images/banners/partnersBanner.jpg');
  background-size: cover;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
  /* ------------------ */
  /* --- Government --- */
  /* ------------------ */

  /* ------------------ */
  /* --- Commercial --- */
  /* ------------------ */

  /* ------------------ */
  /* ---- Partners ---- */
  /* ------------------ */
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
  /* ------------------ */
  /* --- Government --- */
  /* ------------------ */

  /* ------------------ */
  /* --- Commercial --- */
  /* ------------------ */

  /* ------------------ */
  /* ---- Partners ---- */
  /* ------------------ */
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  #vendors .sectionWidth {
    width: 75%;
  }

  #vendors #government .padding,
  #vendors #partners .padding {
    padding: 50px;
  }

  /* ------------------ */
  /* --- Government --- */
  /* ------------------ */

  /* ------------------ */
  /* --- Commercial --- */
  /* ------------------ */

  #vendors #commercial.padding {
    padding: 150px 0px 150px 0px;
  }

  /* ------------------ */
  /* ---- Partners ---- */
  /* ------------------ */
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
  /* ------------------ */
  /* --- Government --- */
  /* ------------------ */

  /* ------------------ */
  /* --- Commercial --- */
  /* ------------------ */

  /* ------------------ */
  /* ---- Partners ---- */
  /* ------------------ */
}
