/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

/* ------------------ */
/* - Pharma Catalog - */
/* ------------------ */

#pharmaCatalog h1 {
  font-size: 2.24em;
}

.back {
  background: none;
  border: none;
  padding: 0;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.back:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f0d9';
  padding-right: 5px;
  display: inline-block;
}

#pharmaCatalog .table thead tr th {
  cursor: pointer;
}

#pharmaCatalog .table tbody tr td {
  cursor: pointer;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  #pharmaCatalog .table,
  #pharmaCatalog .table thead,
  #pharmaCatalog .table tbody,
  #pharmaCatalog .table th,
  #pharmaCatalog .table td,
  #pharmaCatalog .table tr {
    display: block;
  }

  #pharmaCatalog .table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #pharmaCatalog .table tr {
    border: 1px solid #ccc;
  }

  #pharmaCatalog .table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30% !important;
  }

  #pharmaCatalog .table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  #pharmaCatalog .tablee td:nth-of-type(1):before {
    content: 'NDC:';
    font-weight: 600;
    padding-top: 2px;
    padding-left: 3px;
  }
  #pharmaCatalog .table td:nth-of-type(2):before {
    content: 'Name:';
    font-weight: 600;
    padding-top: 2px;
    padding-left: 3px;
  }
  #pharmaCatalog .table td:nth-of-type(3):before {
    content: 'Strength:';
    font-weight: 600;
    padding-top: 2px;
    padding-left: 3px;
  }
  #pharmaCatalog .table td:nth-of-type(4):before {
    content: 'Count:';
    font-weight: 600;
    padding-top: 2px;
    padding-left: 3px;
  }
  #pharmaCatalog .table td:nth-of-type(5):before {
    content: 'Form:';
    font-weight: 600;
    padding-top: 2px;
    padding-left: 3px;
  }
}

/* ------------------ */
/* - Pharma Detail -- */
/* ------------------ */

#pharmaDetail .drugImage {
  width: 100%;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
  /* ------------------ */
  /* - Pharma Catalog - */
  /* ------------------ */

  /* ------------------ */
  /* - Pharma Detail -- */
  /* ------------------ */
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media (min-width: 769px) {
  /* ------------------ */
  /* - Pharma Catalog - */
  /* ------------------ */

  #pharmaCatalog .table thead tr th:nth-child(1) {
    width: 15%;
  }

  #pharmaCatalog .table thead tr th:nth-child(2) {
    width: 45%;
  }
  #pharmaCatalog .table thead tr th:nth-child(3) {
    width: 15%;
  }
  #pharmaCatalog .table thead tr th:nth-child(4) {
    width: 15%;
  }
  #pharmaCatalog .table thead tr th:nth-child(5) {
    width: 20%;
  }

  /* ------------------ */
  /* - Pharma Detail -- */
  /* ------------------ */
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  /* ------------------ */
  /* - Pharma Catalog - */
  /* ------------------ */

  #pharmaCatalog .table,
  #pharmaCatalog .width {
    width: 90%;
  }

  /* ------------------ */
  /* - Pharma Detail -- */
  /* ------------------ */
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
  /* ------------------ */
  /* - Pharma Catalog - */
  /* ------------------ */

  /* ------------------ */
  /* - Pharma Detail -- */
  /* ------------------ */
}
