/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#whoWeAre .width {
  /* padding: 50px 50px 50px 100px; */
  width: 90%;
}

#whoWeAre #valuedCustomers ul {
  margin-left: 10px;
}

#whoWeAre #valuedCustomers li {
  padding-left: 15px;
}

#whoWeAre #products.colorBg {
  background-color: #535353;
}

#whoWeAre #logistics.colorBg {
  background-color: #ce373d;
}

#whoWeAre #products .box,
#whoWeAre #logistics .box {
  padding: 40px 0px 40px 0px;
  width: 100%;
  height: auto;
}

#whoWeAre #products p,
#whoWeAre #logistics p {
  padding: 0px 60px 0px 60px;
}

#whoWeAre #valuedCustomers.colorBg {
  background-color: #ededed;
}

#whoWeAre #commitment ul {
  padding-left: 17px;
  font-size: 0.9em;
}

#whoWeAre #commitment ul li {
  padding-bottom: 5px;
}

#whoWeAre #commitment .equipList {
  font-size: 1.2em;
}

#whoWeAre .btnCustom {
  margin-left: 60px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #000;
  font-size: 0.6em;
  padding: 7px 20px 7px 20px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #000;
  text-decoration: none;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
  #whoWeAre .width {
    width: 83%;
  }

  #WhoWeAre #products .box,
  #WhoWeAre #logistics .box {
    width: 75%;
    height: auto;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
}
