.colorBg {
    background-color: #e5ecf0;
}

.lightblue-bg {
    background-color: #85CDE9;
}

.darkblue-bg {
    background-color: #14296a;
}

.darkblue-text {
    color: #85CDE9;
}

.lightblue-text {
    color: #14296a;
}

.whiteText {
    color: whitesmoke;
}

/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#dscsa .width {
    /* padding: 50px 50px 50px 100px; */
    width: 90%;
  }
  
  #dscsa .links ul {
    margin-left: 10px;
  }
  
  #dscsa .links li {
    padding-left: 15px;
  }
  
  #dscsa .products.colorBg {
    background-color: #535353;
  }
  
  #dscsa #logistics.colorBg {
    background-color: #ce373d;
  }
  
  #dscsa .products .box,
  #dscsa #logistics .box {
    padding: 40px 0px 40px 0px;
    width: 100%;
    height: auto;
  }
  
  #dscsa .products p,
  #dscsa #logistics p {
    padding: 0px 60px 0px 60px;
  }
  
  #dscsa .links.colorBg {
    background-color: #ededed;
  }
  
  #dscsa #commitment ul {
    padding-left: 17px;
    font-size: 0.9em;
  }
  
  #dscsa #commitment ul li {
    padding-bottom: 5px;
  }
  
  #dscsa #commitment .equipList {
    font-size: 1.2em;
  }
  
  #dscsa .btnCustom {
    margin-left: 60px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #000;
    font-size: 0.6em;
    padding: 7px 20px 7px 20px;
    font-weight: 800;
    letter-spacing: 1px;
    color: #000;
    text-decoration: none;
  }
  
  /* ---------------------------------------------- */
  /* ---------------------------------------------- */
  /* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
  /* ---------------------------------------------- */
  /* ---------------------------------------------- */
  
  @media screen and (min-width: 576px) {
  }
  
  /* ----------------------------- */
  /* ----------------------------- */
  /* -------TABLET Layout-------- */
  /* ----------------------------- */
  /* ----------------------------- */
  
  @media screen and (min-width: 769px) {
    #dscsa .width {
      width: 83%;
    }
  
    #dscsa .products .box,
    #dscsa #logistics .box {
      width: 75%;
      height: auto;
    }
  }
  
  /* ----------------------------- */
  /* ----------------------------- */
  /* -------DESKTOP Layout-------- */
  /* ----------------------------- */
  /* ----------------------------- */
  
  @media screen and (min-width: 992px) {
  }
  
  /* ----------------------------- */
  /* ----------------------------- */
  /* ----Large desktop changes---- */
  /* ----------------------------- */
  /* ----------------------------- */
  
  @media screen and (min-width: 1200px) {
  }
  