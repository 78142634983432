/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

/* ------------------ */
/* ---Hero Section--- */
/* ------------------ */

#home #hero .header {
  border: none;
  border-radius: 0;
  background-color: #393630;
}

#home #hero .header .image {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 100%,
      transparent 100%
    ),
    url('../assets/images/banners/bottlesOnConveyerBelt.jpg');
  height: 32em;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
}

#home #hero .header .overlay {
  border-radius: 0;
  padding: 0;
  display: flex;
}

#home #hero h1 {
  width: 100vw;
  font-size: 1.8em;
  line-height: 1.5;
  padding: 20px 10px 20px 10px;
  text-align: center;
}

#home #hero .callToAction {
  background-color: #c31e33;
  width: 100%;
  font-size: 0.85em;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 20px 10px 20px 10px;
  text-align: center;
}

/* ------------------ */
/* --Vendor Section-- */
/* ------------------ */

#home #vendorCards .colorCol1 {
  background-image: url('../assets/images/backgrounds/rectangleGray.png');
}

#home #vendorCards .colorCol2 {
  background-image: url('../assets/images/backgrounds/rectangleBlack.png');
}

#home #vendorCards .colorCol3 {
  background-image: url('../assets/images/backgrounds/rectangleRed.png');
}

#home #vendorCards .cardControl {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home #vendorCards .cardHeaderControl {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}

#home #vendorCards .icon {
  font-size: 4em;
  padding: 0rem;
}

#home #vendorCards .cardTitleControl {
  font-size: 1.6em;
  font-weight: bold;
  padding: 5px;
}

#home #vendorCards .cardTextControl {
  font-size: 1em;
  padding: 0px 8%;
}

#home #vendorCards .padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

#home #vendorCards .transparentBtn {
  padding: 0.3rem 1.5rem;
  font-size: 0.7em;
  letter-spacing: 1px;
  font-weight: bolder;
  margin-left: auto;
  text-decoration: none;
  margin-top: 10px;
}

/* ------------------ */
/* ---- Carousel ---- */
/* ------------------ */

#home #carousel .carouselImage {
  width: 60%;
  margin: 5% 20%;
  box-shadow: none;
  border: 1px solid black;
}

#home #carousel .carouselContent {
  text-align: center;
  color: #4c4c4c;
  padding: 0% 10%;
  font-size: 1em;
  height: 350px;
}

#home #carousel .carouselTitle {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 2.3em;
  /* padding-bottom: 15px;
  padding-top: 15px; */
  padding-bottom: 20px;
  padding-top: 5px;
  color: #000;
}

/* #home #carousel .carouselTitle {
  padding-bottom: 20px;
  padding-top: 5px;
} */

#home #carousel .carouselControls {
  color: black;
  width: 10%;
  align-items: flex-start;
}

#home #carousel .carousel-indicators {
  bottom: 0px;
}

#home #carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d8d8d8;
}

#home #carousel .carousel-indicators > .active {
  background-color: black;
}

#home #carousel .carouselControls:focus {
  outline: none;
}

#home #carousel .chevron {
  height: 25px;
  color: #999;
}

#home #carousel .chevronLeft {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#home #carousel .carousel-control-next-icon,
#home #carousel .carousel-control-prev-icon {
  background-image: none;
  color: #999999;
  position: absolute;
  top: 30vw;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
  /* padding: 50px 0; */
  padding-top: 50px;
  padding-bottom: 50px;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
}

/* ------------------ */
/*  Unit Dose Section */
/* ------------------ */

#home #unitDoseCatalog.bgLightGrey {
  background-color: #eee;
}

#home #unitDoseCatalog .btnBarcodeCatalog {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #000;
  font-size: 0.7em;
  padding: 7px 20px 7px 20px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #000;
  text-decoration: none;
}

#home #unitDoseCatalog .unitDoseContent {
  width: 100%;
  font-size: 1em;
  margin: auto;
  text-align: center;
  padding-top: 30px;
  height: 100%;
}

#home #unitDoseCatalog h2 {
  font-size: 2.3em;
  padding-bottom: 10px;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
  /* ------------------ */
  /* ---Hero Section--- */
  /* ------------------ */

  #home #hero .header .image {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.6) 1%,
        transparent 100%
      ),
      url('../assets/images/banners/bottlesOnConveyerBelt.jpg');
  }

  #home #hero h1 {
    font-size: 2em;
    padding: 20px 60px 20px 10%;
    text-align: left;
  }

  #home #hero .callToAction {
    padding: 20px 30px 20px 10%;
    text-align: left;
  }

  /* ------------------ */
  /* --Vendor Section-- */
  /* ------------------ */

  #home #vendorCards .cardHeaderControl {
    flex: 1;
  }

  #home #vendorCards .cardTextControl {
    flex: 100;
  }

  /* ------------------ */
  /* ---- Carousel ---- */
  /* ------------------ */

  #home #carousel .carouselTitle {
    font-size: 2.8em;
  }

  #home #carousel .carouselTitle.custom {
    font-size: 2.4em;
  }

  #home #carousel .carouselTitle.custom .small {
    font-size: 0.5em;
  }

  #home #carousel .carouselContent {
    height: 300px;
  }

  /* ------------------ */
  /*  Unit Dose Section */
  /* ------------------ */

  #home #unitDoseCatalog h2 {
    font-size: 2.8em;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
  /* ------------------ */
  /* ---Hero Section--- */
  /* ------------------ */

  #home #hero h1 {
    width: 75%;
    padding: 20px 30px 20px 5%;
  }

  #home #hero .callToAction {
    width: 75%;
    padding: 12px 30px 12px 5%;
  }

  /* ------------------ */
  /* --Vendor Section-- */
  /* ------------------ */

  #home #vendorCards .icon {
    padding: 0em;
  }

  #home #vendorCards .cardTitleControl {
    margin: 0.75em 0rem;
  }

  /* ------------------ */
  /* ---- Carousel ---- */
  /* ------------------ */

  /* ------------------ */
  /*  Unit Dose Section */
  /* ------------------ */

  #home #unitDoseCatalog .unitDoseContent {
    text-align: left;
    padding: 30px 30px 0px 30px;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  /* ------------------ */
  /* ---Hero Section--- */
  /* ------------------ */

  #home #hero .header {
    margin-top: 0px;
  }

  #home #hero h1 {
    width: 65%;
    padding: 20px 60px 20px 8%;
  }

  #home #hero .callToAction {
    width: 60%;
    padding: 15px 0px 15px 8%;
  }

  #home #hero h1.animation {
    -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 4s; /* Firefox < 16 */
    -ms-animation: fadein 4s; /* Internet Explorer */
    -o-animation: fadein 4s; /* Opera < 12.1 */
    animation: fadein 4s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #home #hero .callToAction.animation {
    overflow: hidden;
    white-space: nowrap;
    -webkit-animation: slide 1.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: slide 1.5s; /* Firefox < 16 */
    -ms-animation: slide 1.5s; /* Internet Explorer */
    -o-animation: slide 1.5s; /* Opera < 12.1 */
    animation: slide 1.5s;
  }

  @keyframes slide {
    from {
      color: transparent;
      width: 0;
      padding-left: 0;
      padding-right: 0;
    }
    to {
      width: 60%;
      color: white;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes slide {
    from {
      color: transparent;
      width: 0;
    }
    to {
      width: 60%;
      color: white;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes slide {
    from {
      color: transparent;
      width: 0;
    }
    to {
      width: 60%;
      color: white;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes slide {
    from {
      color: transparent;
      width: 0;
    }
    to {
      width: 60%;
      color: white;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes slide {
    from {
      color: transparent;
      width: 0;
    }
    to {
      width: 60%;
      color: white;
    }
  }

  /* ------------------ */
  /* --Vendor Section-- */
  /* ------------------ */

  #home #vendorCards .cardControl {
    padding: 4%;
  }

  #home #vendorCards .cardTitleControl {
    margin: 0.75em 0em;
  }

  /* ------------------ */
  /* ---- Carousel ---- */
  /* ------------------ */

  #home #carousel .carouselImage {
    margin: 0 2%;
    width: 40%;
  }

  #home #carousel .carouselContent {
    width: 43%;
    padding: 0;
    text-align: left;
    margin-bottom: 0;
    height: 400px;
    padding-right: 20px;
  }

  #home #carousel .carouselTitle {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  #home #carousel .lastChild {
    margin-bottom: 0em;
  }

  #home #carousel .carouselControls {
    width: 10%;
  }

  #home #carousel .carousel-control-next-icon,
  #home #carousel .carousel-control-prev-icon {
    top: 48%;
  }

  #home #carousel #carouselExampleIndicators {
    margin-top: 3.5%;
  }

  #home #carousel .carousel-item {
    display: none;
    justify-content: center;
  }

  #home #carousel .carousel-item.active {
    display: flex;
    justify-content: center;
  }

  /* ------------------ */
  /*  Unit Dose Section */
  /* ------------------ */

  #home #unitDoseCatalog .unitDoseContent {
    width: 75%;
    padding: 0px;
    margin-left: 15%;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
  /* ------------------ */
  /* ---Hero Section--- */
  /* ------------------ */

  /* ------------------ */
  /* --Vendor Section-- */
  /* ------------------ */

  #home #vendorCards .cardTitleControl {
    margin-bottom: 0;
  }

  /* ------------------ */
  /* ---- Carousel ---- */
  /* ------------------ */

  /* ------------------ */
  /*  Unit Dose Section */
  /* ------------------ */
}

@media screen and (min-width: 1500px) {
  #home #hero .header .image {
    height: 35em;
  }
}
