.modal-xl {
    max-width: 90%;
}

.hidden {
    display: none;
}

.form-control.signature1{
    font-family: 'Homemade Apple', cursive;
}
.form-control.signature2{
    font-family: 'Dancing Script', cursive;
}

/* Bootstrap modal overrides */
#newCustomerApplicationModal .nav {
    flex-wrap: nowrap;
}
#newCustomerApplicationModal .nav-link {
    font-size: 16px;
    white-space: break-spaces;
    position: relative;
}

#newCustomerApplicationModal .nav-link::before {
    background: unset;
    display: unset;
    width: unset;
    height: unset;
    margin: unset;
}

.tab-incomplete, .tab-complete {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-footer
{
    justify-content: space-between;
}

#newCustomerApplicationModal .accordion-button{
    border: unset;
    border-bottom: 2px solid #41464b;
    border-radius: unset;
    box-shadow: unset;
}

#newCustomerApplicationModal .accordion-item {
    border-radius: unset;
    border: unset;
    background-color: unset;
}

#newCustomerApplicationModal .accordion-body {
    /* background-color: snow; */
    margin-top: 16px;
}

#newCustomerApplicationModal .dup-button {
    line-height: 32px;
    padding: 0px;
    
    width: 32px;
    border: none;
}

#newCustomerApplicationModal .dup-button svg {
    margin: 0 !important;
}

#review-output .fieldName{
    font-style: italic;
}

#review-output .fieldValue{
    font-weight: bold;
}