/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#careers #opps.colorBg {
  background-color: #4c4c4c;
}

#careers #opps p {
  line-height: 1.8em;
  padding: 30px;
}

#careers #introduction p {
  line-height: 1.8em;
  padding: 30px;
}

.jobs-apply-button {
  margin-top: 20px;
  display: block;
  width: 100px;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #000;
  font-size: 0.6em;
  padding: 7px 20px 7px 20px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #000;
  text-decoration: none;
}
.jobs-apply-button:hover {
  background-color: #e71e3c;
  color: #fff;
}

#careers #info p {
  color: #666;
  line-height: 1.8em;
}

#careers #info .card {
  background-color: #fbfbfb;
}

#careers #info .card-body {
  padding: 40px;
}

#careers #info .width {
  width: 100%;
}

#careers #info #missionStatement {
  background-image: url('../assets/images/backgrounds/benefits.jpg');
  background-size: cover;
}

#careers li {
  color: 000;
  /* font-size: 0.9em; */
  display: inline-block;
  word-wrap: break;
}

#careers li:before {
  content: '\00a0\2022\00a0\00a0';
}

#careers #equalOpportunity.colorBg {
  background-color: #999;
}

#careers #equalOpportunity p {
  line-height: 1.8em;
  padding: 30px;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
  #careers #introduction p {
    padding: 50px 70px 20px 70px;
  }

  #careers #opps p {
    padding: 50px;
  }

  #careers #info .floatRight {
    justify-content: flex-end;
  }

  #careers #equalOpportunity p {
    padding: 50px;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  #careers #info .width {
    width: 90%;
  }

  #careers #opps p {
    padding: 70px;
  }

  #careers #equalOpportunity p {
    padding: 70px;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
}
