/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#footer {
  font-size: 1em;
  color: #fff;
  padding: 45px 0px 0px 0px;
  text-align: center;
  background: url('../assets/images/backgrounds/swoosh.png');
  background-repeat: no-repeat;
  background-size: 40px 100%;
  background-color: #333333;
}

#footer ul {
  list-style: none;
  padding: 0;
}

#footer a {
  text-decoration: none;
  color: #a2a2a2;
}

#footer li {
  padding-top: 5px;
  padding-bottom: 5px;
}

#footer a:hover {
  color: #fff;
}

#footer .copyright {
  color: #aaa;
  font-size: 0.8em;
  float: right;
  padding: 25px 0px 15px 0px;
  margin: 0;
}

#footer .sdvosbLogo {
  display: block;
  width: 100%;
  height: auto;
  margin: auto;
}

#footer .avkareLogo {
  display: block;
  width: 90%;
  height: auto;
  margin: auto;
}

#footer .minusSymbol::before {
  content: '_ ';
  color: transparent;
}

#footer .minusSymbol::after {
  content: ' -';
}

#footer .plusSymbol::before {
  content: '_ ';
  color: transparent;
}

#footer .plusSymbol::after {
  content: ' +';
}

#footer .minusSymbol::after,
#footer .minusSymbol::before,
#footer .plusSymbol::after,
#footer .plusSymbol::before {
  font-size: 1em;
}

#footer .toggleSelect {
  font-size: 1.1em;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#footer .toggleSelect:hover {
  font-size: 1.1em;
  color: #a2a2a2;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
  #footer {
    text-align: left;
  }

  #footer .sdvosbLogo {
    width: 70%;
  }

  #footer .minusSymbol::before {
    content: none;
  }

  #footer .minusSymbol::after {
    content: none;
  }

  #footer .plusSymbol::before {
    content: none;
  }

  #footer .plusSymbol::after {
    content: none;
  }

  #footer .toggleSelect {
    cursor: default;
  }

  #footer .toggleSelect:hover {
    color: inherit;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  #footer li {
    padding: 0px;
  }

  #footer .sdvosbLogo {
    width: 55%;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
}
