/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#error {
  height: 100vh;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
}
