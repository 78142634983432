#recall-notices {
    margin: 0 10%;
    font-size: 1.2em;
}

img {
    width: 100%;
}
img.third-width {
    width: 30%;
}

table {
    width: 100%;
}
th, td {
    border: 1px solid;
    padding: 4px;
}
.non-list {
    display: block;
}