/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#navBar .redStrip {
  border-top: 10px #d92034 solid;
}

.navbar {
  font-family: 'ProximaNovaSemiBold';
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  max-height: 100%;
}

.navbar .dropdown-menu .indent {
  margin-left: 40%;
  width: 50% !important;
  text-align: left;
}

.navbar a {
  text-decoration: none;
  color: #394351;
}

.navbar a:hover,
.navbar a:active,
.nav-link:hover,
.nav-link:active,
.navbar .dropdown-item:hover,
.navbar .dropdown-item:active,
.navbar .dropdown-item.active {
  color: #ce373d;
  transition-duration: 0.15s;
}

.navbar .nav-link {
  border-bottom: #eee 1px solid;
}

.navbar .dropdown-item:active,
.navbar .dropdown-item.active {
  background-color: transparent;
}

#navBar .avkareLogo {
  width: 10.5rem;
  padding: 1rem;
}

.nav-link {
  text-transform: uppercase;
}

.nav-link:before {
  content: '';
  background-image: url(../assets/images/icons/star.png);
  background-size: 12px 12px;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.nav-link.waltzd:before {
  background-image: none;
  background-size: initial;
}
.nav-link.waltzd:not(:last-child){
  border-right: 1px solid;
}
.nav-link.waltzd:hover {
  color: #12588B;
}

.nav-link {
  font-size: 20px;
  text-align: center;
  padding: 20px;
}

.navigationContent {
  display: flex;
  flex-direction: column;
}

.dropdown-menu {
  font-size: 18px;
  border: none;
  text-align: center;
  background: #fff;
  -webkit-tap-highlight-color: #ddd;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-toggle::after {
  color: #b2b2b2;
}

.nav-item:active,
.nav-link:active,
.dropdown-item:active {
  /* filter: opacity(75%); */
  transition-duration: 0.15s;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  .navbar {
    padding: 0;
    position: initial;
    width: 100%;
    overflow-y: initial;
  }

  #navBar .avkareLogo {
    margin-left: 2rem;
  }

  .navigationContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 2vw;
    margin-left: -10px;
    width: 100%;
  }

  .navbar .nav-link {
    font-size: 1rem;
    text-align: left;
    border: none;
  }

  .dropdown-menu {
    font-size: 16px;
  }

  .dropdown-item {
    padding: 0.25rem 1.5rem;
    background-color: #fff;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
  .navbar {
    position: relative;
    z-index: 1030;
  }

  #navBar .avkareLogo {
    width: 14rem;
  }

  .navigationContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 2vw;
    width: 100%;
  }

  .navbar .nav-link {
    font-size: 1.2rem;
  }
}
