/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

#consumerInfo {
  background: linear-gradient(#eee, #fff, #fff);
  margin-left: -15px;
  margin-right: -15px;
}

#consumerInfo .icon {
  padding-top: 30px;
  padding-bottom: 5px;
  width: 120px;
}

#consumerInfo .width {
  width: 85%;
}

#consumerInfo .navyText {
  color: #273144;
}

#consumerInfo .pillStackBg {
  background-image: url('../assets/images/backgrounds/pillStack.png');
  background-size: cover;
}

#consumerInfo strong .phone {
  color: #cf373d;
  text-decoration: none;
}

#consumerInfo .introText {
  font-size: 1.4em;
  width: 75%;
}

#consumerInfo .introText h1 {
  padding-bottom: 5px;
  font-size: 1.6em;
}

#consumerInfo .outroText {
  font-size: 1.2em;
  padding-bottom: 35px;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 769px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 1200px) {
}
