#returnPolicy.page {
  height: 50vh;
}

#returnPolicy .btn {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #000;
  font-size: 0.7em;
  padding: 7px 20px 7px 20px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #000;
  text-decoration: none;
}
