@font-face {
  font-family: 'ProximaNovaThin';
  src: url('../fonts/Mark Simonson - Proxima Nova Thin-webfont.ttf');
}

@font-face {
  font-family: 'ProximaNovaRegular';
  src: url('../fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.ttf');
}

@font-face {
  font-family: 'ProximaNovaSemiBold';
  src: url('../fonts/Mark Simonson - Proxima Nova Semibold-webfont.ttf');
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('../fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.ttf');
}

@font-face {
  font-family: 'ProximaNovaExtraBold';
  src: url('../fonts/Mark Simonson - Proxima Nova Alt Black-webfont.ttf');
}


/* ----------------------------- */
/* ----------------------------- */
/* --------Mobile Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

html {
  max-width: 1280px;
  margin: auto;
}

body {
  min-width: 320px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  font-family: Arial;
  font-size: 12pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ddd;
  line-height: 1.4;
  box-shadow: 1px 6px 20px rgba(0, 0, 0, 0.1), -1px 6px 20px rgba(0, 0, 0, 0.1);
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) .nav-link:focus {
  outline: none;
}

a {
  text-decoration: underline;
  color: #2a639b;
}

.square {
  border-radius: 0px;
  border: none;
  width: 100%;
}

.blueText {
  color: #0364a0;
}

.textRed {
  color: #e71e3c;
}

.grayText {
  color: #4c4c4c;
}

.greenText {
  color: #99C158;
}
/* 
.card-text {
  font-size: 0.9rem;
} */

.semiBold {
  font-family: 'ProximaNovaSemiBold';
}

.bold {
  font-family: 'ProximaNovaBold';
}
.bolder {
  font-weight: bolder;
}

.super-large {
  font-size: 3em;
}

.uppercase {
  text-transform: uppercase;
}

.georgiaFont {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.bgWhite {
  background-color: #fff;
}

.btn-primary {
  color: #fff !important;
  background-color: #e71e3d;
  padding: 6px 25px 6px 25px;
  font-size: 0.7em;
  font-weight: bolder;
  letter-spacing: 1px;
  border: 0;
  border-radius: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #e71e3d;
}

/* Why isn't this working */
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #c21932fd;
}

.grayPatternBg {
  background-image: url('../assets/images/backgrounds/rectangleGray.jpg');
  background-color: #858585;
}

.blackPatternBg {
  background-image: url('../assets/images/backgrounds/rectangleBlack.jpg');
  background-color: #333333;
}

.redPatternBg {
  background-image: url('../assets/images/backgrounds/rectangleRed.jpg');
  background-color: #d0373d;
}

.socialMediaIcons img {
  width: 2em;
  margin: 2px;
}

.breakWord {
  overflow-wrap: break-word;
}

/* ---------------------------------------------- */
/* ---------------------------------------------- */
/* ------  SMALL TABLET & LANDSCAPE PHONE ------- */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

@media screen and (min-width: 576px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------TABLET Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media (min-width: 769px) {
}

/* ----------------------------- */
/* ----------------------------- */
/* -------DESKTOP Layout-------- */
/* ----------------------------- */
/* ----------------------------- */

@media screen and (min-width: 992px) {
  html {
    zoom: 0.9;
  }

  .page {
    padding-top: 0px;
  }
}

/* ----------------------------- */
/* ----------------------------- */
/* ----Large desktop changes---- */
/* ----------------------------- */
/* ----------------------------- */

@media (min-width: 1200px) {
}

@media (min-width: 1500px) {
  html {
    zoom: 1;
  }
}
